import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/S7a4oj0t5Nk">
    <SEO title="Seeing God's Best - Counter Culture" />
  </Layout>
)

export default SermonPost
